import { StateBasicReward } from "../redux/basicReward";
import { StateBasicReward2024 } from "../redux/basicReward2024";
import { StateError, Message } from "../redux/error";
import { StateTransferBasicReward } from "../redux/transferBasicReward";
import { StateTypeBBasicReward } from "../redux/typeBBasicReward";

type PropsBasicReward = {
  basicReward: StateBasicReward | undefined,
}
type PropsBasicReward2024 = {
  basicReward: StateBasicReward2024 | undefined,
}
type PropsTypeBBasicReward = {
  basicReward: StateTypeBBasicReward | undefined,
}
type PropsTransferBasicReward = {
  basicReward: StateTransferBasicReward | undefined,
}

export const checkBasicReward = (props: PropsBasicReward) => {
  let messages: Message[] = [];
  const { basicReward } = props;

  if(!basicReward) {
    messages.push({
      text:`2023年度以前の基本報酬情報が登録されていません`,
      url: `organization?tab=rewards`,
      type: 'error',
    })
    const error = messages.map(m => { return {mainMenu:'事業所設定', subMenu:'事業所基本情報', message: m} as StateError });
    return error;
  }
  const error = messages.map(m => { return {mainMenu:'事業所設定', subMenu:'事業所基本情報', message: m} as StateError });
  return error;
}
export const checkBasicReward2024 = (props: PropsBasicReward2024) => {
  let messages: Message[] = [];
  const { basicReward } = props;

  if(!basicReward) {
    messages.push({
      text:`2024年度の基本報酬情報が登録されていません`,
      url: `organization?tab=rewards`,
      type: 'error',
    })
    const error = messages.map(m => { return {mainMenu:'事業所設定', subMenu:'事業所基本情報', message: m} as StateError });
    return error;
  }
  const error = messages.map(m => { return {mainMenu:'事業所設定', subMenu:'事業所基本情報', message: m} as StateError });
  return error;
}

export const checkTypeBBasicReward = (props: PropsTypeBBasicReward) => {
  let messages: Message[] = [];
  const { basicReward } = props;

  if(!basicReward) {
    messages.push({
      text:`基本報酬情報が登録されていません`,
      url: `organization?tab=rewards`,
      type: 'error',
    })
    const error = messages.map(m => { return {mainMenu:'事業所設定', subMenu:'事業所基本情報', message: m} as StateError });
    return error;
  }
  const error = messages.map(m => { return {mainMenu:'事業所設定', subMenu:'事業所基本情報', message: m} as StateError });
  return error;
}

export const checkTransferBasicReward = (props: PropsTransferBasicReward) => {
  let messages: Message[] = [];
  const { basicReward } = props;

  if(!basicReward) {
    messages.push({
      text:`基本報酬情報が登録されていません`,
      url: `organization?tab=rewards`,
      type: 'error',
    })
    const error = messages.map(m => { return {mainMenu:'事業所設定', subMenu:'事業所基本情報', message: m} as StateError });
    return error;
  }
  const error = messages.map(m => { return {mainMenu:'事業所設定', subMenu:'事業所基本情報', message: m} as StateError });
  return error;
}