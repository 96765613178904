import { StateUser} from '../redux/users';
import dayjs from  '../lib/dayjs';
import { StateUsageRecord } from '../redux/usageRecords';

// サービス提供期間が対象月内かつ対象月内に実績のあるユーザーのみのuserリストを返す
export const outputUsersInMonth = (
  users: StateUser[],
  yearMonth: string,
  usageRecords: StateUsageRecord[],
  ) => {
  const startDate = dayjs(yearMonth, 'YYYY/MM').format('YYYY/MM/DD');
  const endDate = dayjs(yearMonth, 'YYYY/MM').endOf('month').format('YYYY/MM/DD');
  return outputUsersInArbitraryPeriod(users, startDate, endDate, usageRecords);
}

export const outputUsersInArbitraryPeriod = (
  users: StateUser[],
  startDate: string,
  endDate: string,
  usageRecords: StateUsageRecord[],
  ) => {
  const hasCertificationUsers = usersWithCertification(users);
  const insidePeriodUsers = insidePeriodUsersInArbitraryPeriod(users, startDate, endDate);
  const usersWithUsageRecords = usersWithUsageRecordsInArbitraryPeriod(users, startDate, endDate, usageRecords);
  return users.filter((user)=>{
    return hasCertificationUsers.includes(user) && insidePeriodUsers.includes(user) && usersWithUsageRecords.includes(user);
  })
}

// 受給者証番号が登録されているユーザーのみのuserリストを返す
const usersWithCertification = (users: StateUser[]) => {
  return users.filter((user)=>{
    return user.beneficiaryNumber;
  })
}

// 対象期間内に実績のあるユーザーのみのuserリストを返す
export const usersWithUsageRecordsInArbitraryPeriod = (
  users: StateUser[],
  startDate: string,
  endDate: string,
  usageRecords: StateUsageRecord[],
  ) => {
  return users.filter((user)=>{
    return isUserWithUsageRecordsInArbitraryPeriod(user.userId, startDate, endDate, usageRecords);
  })
}

// 対象のユーザーに実績があるか？
export const isUserWithUsageRecordsInArbitraryPeriod = (
  userId: string,
  startDate: string,
  endDate: string,
  usageRecords: StateUsageRecord[],
  ) => {
  const targetUsageRecords = usageRecords
    .filter(usageRecord => usageRecord.userId===userId)
    .filter(usageRecord => dayjs(usageRecord.attendanceRecord.startAtMillis).isSameOrAfter(startDate, 'day'))
    .filter(usageRecord => dayjs(usageRecord.attendanceRecord.startAtMillis).isSameOrBefore(endDate, 'day'))
    .filter(usageRecord => usageRecord.serviceProvisionStatus!=="absence");
  return targetUsageRecords.length;
}

// サービス提供期間内のユーザーのみのuserリストを返す
export const insidePeriodUsers = (
  users: StateUser[],
  date?: string,
  ) => {
  return users.filter((user)=>{
      return isInsidePeriodUser(users, user.userId, date)
    })
}

// サービス提供期間が対象月内のユーザーのみのuserリストを返す
export const insidePeriodUsersInMonth = (
  users: StateUser[],
  yearMonth: string,
  ) => {
  return users.filter((user)=>{
      return isInsidePeriodUserInMonth(users, user.userId, yearMonth)
    })
}

// サービス提供期間が対象月内のユーザーのみのuserリストを返す
export const insidePeriodUsersInArbitraryPeriod = (
  users: StateUser[],
  startDate: string,
  endDate: string,
  ) => {
  return users.filter((user)=>{
      return isInsidePeriodUserInArbitraryPeriod(users, user.userId, startDate, endDate)
    })
}

// 対象のユーザーがサービス提供期間内か？
export const isInsidePeriodUserInMonth = (
  users: StateUser[],
  userId: string,
  yearMonth: string
  ) => {
  const startDate = dayjs(yearMonth, 'YYYY/MM').format('YYYY/MM/DD');
  const endDate = dayjs(yearMonth, 'YYYY/MM') .add(1, 'M').subtract(1, 'D').format('YYYY/MM/DD');
  return isInsidePeriodUserInArbitraryPeriod(users, userId, startDate, endDate);
}

// 対象のユーザーがサービス提供期間内か？
export const isInsidePeriodUserInArbitraryPeriod = (
  users: StateUser[],
  userId: string,
  startDate: string,
  endDate: string,
  ) => {
  const filteredUser = users.find((u)=>{
    return(u.userId === userId &&
      u.hasReceivedService.startAtMillis &&
      u.hasReceivedService.endAtMillis &&
      (dayjs(u.hasReceivedService.startAtMillis).isSameOrBefore(endDate, 'day') &&
      dayjs(u.hasReceivedService.endAtMillis).isSameOrAfter(startDate, 'day'))
      )
    })
  return filteredUser || notInputHasReceivedServiceEndDate(users, userId)
}

// 対象のユーザーがサービス提供期間内か？
export const isInsidePeriodUser = (
  users: StateUser[],
  userId: string,
  date?: string
  ) => {
  const filteredUsers = users.filter((u)=>{
    return(u.userId === userId &&
      u.hasReceivedService.startAtMillis &&
      u.hasReceivedService.endAtMillis &&
      dayjs(date).isSameOrBefore(u.hasReceivedService.endAtMillis , 'day')
      )
    })
  return filteredUsers.length > 0|| notInputHasReceivedServiceEndDate(users, userId)
}

// 対象userの最新のサービス提供終了日を取得する
export const getEndOfReceivedServiceDate = (users: StateUser[], userId: string) =>{
  const user = users.filter((user)=> !!user.hasReceivedService.endAtMillis).sort((a, b)=> {
    const bEndAtMills = b.hasReceivedService.endAtMillis as number
    const aEndAtMills = a.hasReceivedService.endAtMillis as number
    return bEndAtMills - aEndAtMills
  }).find((plan)=> plan.userId === userId)
  return user?.hasReceivedService.endAtMillis ? dayjs(user.hasReceivedService.endAtMillis).format('YYYY年M月D日') as string : undefined;
}

const notInputHasReceivedServiceEndDate = (users: StateUser[], userId: string) => {
  return !!users.find(user=> user.userId === userId && user.hasReceivedService.endAtMillis === null)
}

export const isAfterHasReceivedServiceEndDate = (users: StateUser[], userId: string, date: string) => {
  const user = users.find(user=> user.userId === userId && user.hasReceivedService.endAtMillis);
  if (!user) return false;
  return dayjs(date).isAfter(dayjs(user.hasReceivedService.endAtMillis)) as boolean
}
