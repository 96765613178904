import { StateAddingRewardItem } from "../redux/addingRewardItem";
import { StateAddingRewardItem2024 } from "../redux/addingRewardItem2024";
import { StateError, Message } from "../redux/error";
import { StateTransferAddingRewardItem } from "../redux/transferAddingRewardItem";
import { StateTypeBAddingRewardItem } from "../redux/typeBAddingRewardItem";
import { StateTypeBAddingRewardItem2024 } from "../redux/typeBAddingRewardItem2024";

type PropsAddingRewardItem = {
  addingRewardItem: StateAddingRewardItem | undefined,
}
type PropsAddingRewardItem2024 = {
  addingRewardItem: StateAddingRewardItem2024 | undefined,
}

type PropsTypeBAddingRewardItem = {
  addingRewardItem: StateTypeBAddingRewardItem | undefined,
}
type PropsTypeBAddingRewardItem2024 = {
  addingRewardItem: StateTypeBAddingRewardItem2024 | undefined,
}

type PropsTransferAddingRewardItem = {
  addingRewardItem: StateTransferAddingRewardItem | undefined,
}

export const checkAddingRewardItem = (props: PropsAddingRewardItem) => {
  let messages: Message[] = [];
  const { addingRewardItem } = props;

  if(!addingRewardItem) {
    messages.push({
      text:`2023年度以前の加算報酬項目が登録されていません`,
      url: `organization?tab=rewards`,
      type: 'error',
    })
    const error = messages.map(m => { return {mainMenu:'事業所設定', subMenu:'事業所基本情報', message: m} as StateError });
    return error;
  }
  messages = messages.concat(checkTypeAAddingRewardItemItems(addingRewardItem));
  const error = messages.map(m => { return {mainMenu:'事業所設定', subMenu:'事業所基本情報', message: m} as StateError });
  return error;
}
export const checkAddingRewardItem2024 = (props: PropsAddingRewardItem2024) => {
  let messages: Message[] = [];
  const { addingRewardItem } = props;

  if(!addingRewardItem) {
    messages.push({
      text:`2024年度の加算報酬項目が登録されていません`,
      url: `organization?tab=rewards`,
      type: 'error',
    })
    const error = messages.map(m => { return {mainMenu:'事業所設定', subMenu:'事業所基本情報', message: m} as StateError });
    return error;
  }
  messages = messages.concat(checkTypeAAddingRewardItemItems(addingRewardItem));
  const error = messages.map(m => { return {mainMenu:'事業所設定', subMenu:'事業所基本情報', message: m} as StateError });
  return error;
}

export const checkTypeBAddingRewardItem = (props: PropsTypeBAddingRewardItem) => {
  let messages: Message[] = [];
  const { addingRewardItem } = props;

  if(!addingRewardItem) {
    messages.push({
      text:`2023年度以前の加算報酬項目が登録されていません`,
      url: `organization?tab=rewards`,
      type: 'error',
    })
    const error = messages.map(m => { return {mainMenu:'事業所設定', subMenu:'事業所基本情報', message: m} as StateError });
    return error;
  }
  messages = messages.concat(checkTypeBAddingRewardItemItems(addingRewardItem));
  const error = messages.map(m => { return {mainMenu:'事業所設定', subMenu:'事業所基本情報', message: m} as StateError });
  return error;
}
export const checkTypeBAddingRewardItem2024 = (props: PropsTypeBAddingRewardItem2024) => {
  let messages: Message[] = [];
  const { addingRewardItem } = props;

  if(!addingRewardItem) {
    messages.push({
      text:`2024年度の加算報酬項目が登録されていません`,
      url: `organization?tab=rewards`,
      type: 'error',
    })
    const error = messages.map(m => { return {mainMenu:'事業所設定', subMenu:'事業所基本情報', message: m} as StateError });
    return error;
  }
  messages = messages.concat(checkTypeBAddingRewardItemItems(addingRewardItem));
  const error = messages.map(m => { return {mainMenu:'事業所設定', subMenu:'事業所基本情報', message: m} as StateError });
  return error;
}

export const checkTransferAddingRewardItem = (props: PropsTransferAddingRewardItem) => {
  let messages: Message[] = [];
  const { addingRewardItem } = props;

  if(!addingRewardItem) {
    messages.push({
      text:`加算報酬項目が登録されていません`,
      url: `organization?tab=rewards`,
      type: 'error',
    })
    const error = messages.map(m => { return {mainMenu:'事業所設定', subMenu:'事業所基本情報', message: m} as StateError });
    return error;
  }
  messages = messages.concat(checkTransferAddingRewardItemItems(addingRewardItem));
  const error = messages.map(m => { return {mainMenu:'事業所設定', subMenu:'事業所基本情報', message: m} as StateError });
  return error;
}

const checkTypeAAddingRewardItemItems = (addingRewardItem: StateAddingRewardItem | StateAddingRewardItem2024) => {
  let messages = checkCommonAddingRewardItemItems(addingRewardItem);
  if (addingRewardItem.supportOfEmploymentTransition.hasSupport && !addingRewardItem.supportOfEmploymentTransition.lastYearsResult) {
    messages.push({
      text:`加算報酬項目：就労移行支援体制加算が選択されていますが、前年度実績の6ヶ月以上就労継続者数が保存されていません`,
      url: `organization?tab=rewards`,
      type: 'error',
    })
  }
  return messages;
}

const checkTypeBAddingRewardItemItems = (addingRewardItem: StateTypeBAddingRewardItem | StateTypeBAddingRewardItem2024) => {
  let messages = checkCommonAddingRewardItemItems(addingRewardItem);
  if (addingRewardItem.supportOfEmploymentTransition.hasSupport && !addingRewardItem.supportOfEmploymentTransition.lastYearsResult) {
    messages.push({
      text:`加算報酬項目：就労移行支援体制加算が選択されていますが、前年度実績の6ヶ月以上就労継続者数が保存されていません`,
      url: `organization?tab=rewards`,
      type: 'error',
    })
  }
  return messages;
}

const checkTransferAddingRewardItemItems = (addingRewardItem: StateTransferAddingRewardItem) => {
  let messages = checkCommonAddingRewardItemItems(addingRewardItem);
  return messages;
}

const checkCommonAddingRewardItemItems = (addingRewardItem: StateAddingRewardItem | StateAddingRewardItem2024 | StateTypeBAddingRewardItem | StateTypeBAddingRewardItem2024 | StateTransferAddingRewardItem) => {
  const messages: Message[] = [];
  if (![1,2,3].includes(addingRewardItem.improvementOfStaffTreatment) && addingRewardItem.improvementOfStaffSpecificTreatment) {
    messages.push({
      text:`加算報酬項目：特定処遇改善加算は処遇改善加算がI、II、IIIの時のみ選択できます`,
      url: `organization?tab=rewards`,
      type: 'error',
    })
  }
  if (![1,2,3].includes(addingRewardItem.improvementOfStaffTreatment) && addingRewardItem.baseUpSupportOfStaff) {
    messages.push({
      text:`加算報酬項目：ベースアップ等支援加算は処遇改善加算がI、II、IIIの時のみ選択できます`,
      url: `organization?tab=rewards`,
      type: 'error',
    })
  }
  return messages;
}
